@tailwind base;
@tailwind components;
@tailwind utilities;


@import url('https://fonts.googleapis.com/css2?family=Cherry+Bomb+One&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300..700&display=swap');

.space-grotesk-bold {
  font-family: "Space Grotesk", sans-serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
}

.poppins-style {
  font-family: "Poppins", sans-serif;
  font-style: normal;
}

.logo {
  background: #8E72A1;
  background: linear-gradient(to right, #8E72A1 0%, #AFA5D7 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.about-radial-background {
  /* Set the background size to cover the entire element */
  background-size: cover;
  /* Create a radial gradient with two color stops */
  background:
    radial-gradient(circle farthest-side,rgb(225, 177, 255) 10%, rgba(255, 255, 255, 0) 10%) 20% 20%,
    radial-gradient(circle at 55% 70%,rgba(255, 156, 234, 0.415) 15%, rgba(255, 255, 255, 0) 15%) 20% 20%,
    radial-gradient(circle at 40% 40%,rgba(125, 99, 207, 0.443) 10%, rgba(255, 255, 255, 0) 10%) 20% 24%,
    radial-gradient(circle at 50% 30%,rgba(176, 122, 183, 0.75) 10%, rgba(255, 255, 255, 0) 10%) 34% 34%;
    /* the circle                                          position / size */
  background-repeat:no-repeat;
}

.home-radial-background {
  /* Set the background size to cover the entire element */
  background-size: cover;
  /* Create a radial gradient with two color stops */
  background:
    radial-gradient(circle at 75% 50%,rgba(225, 177, 255, 0.399) 10%, rgba(255, 255, 255, 0) 10%) 20% 20%,
    radial-gradient(circle at 85% 60%,rgba(166, 81, 252, 0.467) 10%, rgba(255, 255, 255, 0) 10%) 20% 20%,
    radial-gradient(circle at 80% 40%,rgba(248, 121, 255, 0.443) 10%, rgba(255, 255, 255, 0) 10%) 20% 24%;
   /* the circle                                          position / size */
  background-repeat:no-repeat;
}

.skill-radial-background {
  /* Set the background size to cover the entire element */
  background-size: cover;
  /* Create a radial gradient with two color stops */
  background:
    radial-gradient(circle at 30% 50%,rgba(255, 126, 240, 0.399) 10%, rgba(255, 255, 255, 0) 10%) 20% 20%; /* the circle                                          position / size */
  background-repeat:no-repeat;
}

.exp-radial-background {
  /* Set the background size to cover the entire element */
  background-size: cover;
  /* Create a radial gradient with two color stops */
  background:
    radial-gradient(circle at 70% 60%,rgba(126, 158, 255, 0.399) 10%, rgba(255, 255, 255, 0) 10%) 20% 20%; /* the circle                                          position / size */
  background-repeat:no-repeat;
}
.right-container{
  display: none;
}
.left-container{
  width: 100%;
}
@media (min-width:920px) {
  .right-container{
    display:flex;
    align-items: center;
    justify-content: center;
  }
  .left-container{
    width: 60%;
  }
}

.active-btn{
  background-color: white;
  color: white;
}
.inactive-btn{
  background-color: transparent;
  color: gray;
}